import { useState, useEffect } from "react";
import * as components from "./components/index";
import ButtonComponent from "./components/Modal/components/Button";
import Panel from "./components/MetricsPanel/Panel";
import { useCurrentSelection, useCurrentMap } from "../contexts";
import { modalCopy } from "./components/MetricsPanel/constants";
import advanced from "../assets/advanced.svg";
import alerts from "../assets/alert.svg";
import ctreesLogo from "../assets/ctrees-sm-logo.png";
import * as styles from "./styles";

export default function JMRVMap() {
  const { currentSelection } = useCurrentSelection();

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState(modalCopy.moreInfo);

  const openModal = (event, modalContent) => {
    event.stopPropagation(); // prevents triggering expandable subsection
    setModalContent(modalContent);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div className={styles.container}>
      <components.Mapbox setDisplayText={currentSelection.setDisplayText} />

      <div className="flex">
        <components.PanelContainer>
          <Panel openModal={openModal} />
        </components.PanelContainer>

        <div className={styles.mainContainer}>
          <div className={styles.searchAndTitleContainer}>
            <div className="flex flex-col items-center justify-center">
              <components.SearchContainer
                setSelection={currentSelection.setSelection}
                displayText={currentSelection.displayText}
              />

              <div className={"z-30 w-fit"}>
                <components.MapTitle title={"Live Biomass Carbon Density Map"}>
                  <ButtonComponent
                    openModal={openModal}
                    modalContent={modalCopy.biomassInformation}
                    icon={"i"}
                  />
                </components.MapTitle>
              </div>
            </div>

            <div className={styles.buttonAdvanceFeaturesDesktop}>
              <ButtonComponent
                openModal={openModal}
                modalContent={modalCopy.advancedMRV}
                text={"Advanced Features"}
                svgIcon={advanced}
                backgroundColor={"#DFF841"}
              />
            </div>
          </div>
          <div className={styles.buttonAdvanceFeaturesMobile}>
            <ButtonComponent
              openModal={openModal}
              modalContent={modalCopy.advancedMRV}
              text={"Advanced Features"}
              svgIcon={advanced}
              backgroundColor={"#DFF841"}
            />
            <components.GradientLegend text={">900 Tons CO2e/ha"} />
          </div>
          <div className={styles.desktopGradientLegend}>
            <components.GradientLegend text={">900 Tons CO2e/ha"} />
          </div>
          <div className={styles.moreInfoButton}>
            <ButtonComponent
              openModal={openModal}
              modalContent={modalCopy.moreInfo}
              text={"More Info"}
              icon={"?"}
            />

            <ButtonComponent
              openModal={openModal}
              modalContent={modalCopy.alerts}
              text={"Subscribe"}
              svgIcon={alerts}
            />
          </div>
        </div>
        <components.ModalComponent
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          modalContent={modalContent}
        />
      </div>
      <a
        className={styles.ctreesLogo}
        href="https://ctrees.org"
        target="_blank"
      >
        <img href="https://ctrees.org" target="_blank" src={ctreesLogo}></img>
      </a>
    </div>
  );
}
