import { createContext, useContext, useState, useEffect, useRef } from "react";
import { getRangeFromCategory } from "../../MapBase/components/MetricsPanel/Panel/components/ChartSection/helpers";
import {
  getUnitsForSingleChart,
  getAverageOfCategoryOverTime,
} from "../../MapBase/components/MetricsPanel/Panel/helpers";
import {
  margin,
  width,
  height,
} from "../../MapBase/components/MetricsPanel/Panel/components/ChartSection/constants";
import * as components from "../../MapBase/components/MetricsPanel/Panel/components";
import {
  initialAreaUnit,
  initialCarbonUnit,
} from "../../MapBase/components/MetricsPanel/Panel/helpers/getUnitsForSingleChart";

import { useCurrentSelection } from "../SelectionProvider";

const ChartContext = createContext();

export function ChartProvider({ product, type, children }) {
  const { currentSelection } = useCurrentSelection();
  const chartContainerRef = useRef(null);
  const chartData = currentSelection.chartData[product];

  const isBarChart = type === "bar";
  const initialUnit = isBarChart ? initialAreaUnit : initialCarbonUnit;

  // chart states
  const [units, setUnits] = useState(initialUnit);
  const [xAxisLimits, setXAxisLimits] = useState(
    getRangeFromCategory(chartData)
  );
  const [chartRange, setChartRange] = useState(xAxisLimits);
  const [chartWidth, setChartWidth] = useState(0);
  const [chartHeight, setChartHeight] = useState(0);

  const updateBaselineData = (newUnits, newRange) => {
    // Compute the new averages based on the new units
    const newAverages = getAverageOfCategoryOverTime(
      chartData,
      newRange,
      newUnits
    );

    currentSelection.setAllMapData((prevAllPanelData) => {
      const updatedData = {
        ...prevAllPanelData,
        baselineData: {
          ...prevAllPanelData.baselineData,
          [product]: {
            ...prevAllPanelData.baselineData[product],
            ...newAverages,
          },
        },
      };

      return updatedData;
    });
  };

  const updateChart = (newData) => {
    if (newData) {
      const ranges = getRangeFromCategory(newData);
      setChartRange(ranges); // Update the range in the parent component

      const newUnits = getUnitsForSingleChart(newData, xAxisLimits);

      setUnits(newUnits);

      updateBaselineData(newUnits, ranges);
    }
  };

  // Callback function to handle the selected range
  const onFilterChange = (id, newRange) => {
    // Compute the new units based on the updated xAxisLimits
    const newUnits = getUnitsForSingleChart(chartData, newRange);
    setUnits(newUnits);

    updateBaselineData(newUnits, newRange);

    // Update the state in a batch
    setUnits(newUnits);
    setXAxisLimits(newRange); // Update the range in the parent component
  };

  useEffect(() => {
    updateChart(chartData);
  }, [chartData]);

  useEffect(() => {
    const marginX = margin.left + margin.right;
    const marginY = margin.top + margin.bottom;
    const chartContainerWidth =
      chartContainerRef.current?.clientWidth - marginX;
    const chartContainerHeight =
      chartContainerRef.current?.clientHeight - marginY;

    setChartWidth(width);
    setChartHeight(
      chartContainerHeight > height ? chartContainerHeight : height
    );
  }, [chartContainerRef.current]);

  useEffect(() => {
    if (chartData) {
      setXAxisLimits(getRangeFromCategory(chartData));
      setChartRange(xAxisLimits);
      updateChart(chartData);
    }
  }, [chartData]);

  const currentChart = {
    units,
    setUnits,
    xAxisLimits,
    chartRange,
    setXAxisLimits,
    onFilterChange,
    chartContainerRef,
    chartWidth,
    chartHeight,
    chart: <components.ChartSection category={product} type={type} />,
    category: product,
    chartData: currentSelection.chartData,
    baselineData: currentSelection.baselineData,
  };
  return (
    <ChartContext.Provider value={{ currentChart }}>
      {children}
    </ChartContext.Provider>
  );
};

export function useCurrentChart() {
  const context = useContext(ChartContext);

  if (context === undefined) {
    throw new Error("useCurrentChart must be used within a ChartProvider");
  }

  return context;
};
