import { useEffect, useRef } from "react";
import { Map, maps } from "@ctrees-products/web-mapbox-interactivity";
import { mapStyle } from "../../constants";
import { useCurrentSelection, useCurrentMap } from "../../../contexts";
import clsx from "clsx";

export default function Mapbox() {
  const { currentSelection } = useCurrentSelection();
  const { currentMap } = useCurrentMap();
  const map = useRef(null); // ref to package map

  //-----------------------------------------//
  //--- INITIALIZE MAP LOAD FUNCTIONALITY ---//
  //-----------------------------------------//
  useEffect(() => {
    // initialize map and store reference
    currentMap.mapRef.current = maps.initMap(mapStyle);
    let currentMapRef = currentMap.mapRef.current;

    // keep on load functionality here to have more control over the load event
    currentMapRef.on("load", () => {
      currentMap.setMapLoaded(true);
      maps.onMapLoad(
        currentMapRef,
        mapStyle.gidOnLoad,
        currentSelection.setSelection,
        mapStyle.tileserverOnLoad,
        mapStyle.minZoom,
        mapStyle.maxZoom
      );
    });

    return () => {
      if (currentMapRef) {
        if (currentMapRef.off) {
          currentMapRef.off("load");
        }
        if (
          currentMap.scaleControlRef.current &&
          currentMap.mapRef.removeControl
        ) {
          currentMapRef.removeControl(currentMap.scaleControlRef.current);
        }
        currentMapRef.remove();
        currentMapRef = null; // Clear the reference to avoid any unintended access
      }
    };
  }, []);

  useEffect(() => {
    if (currentMap.mapRef.current) {
      map.current = currentMap.mapRef.current; // keep package map ref in sync with context map ref
    }
  }, [currentMap.mapRef.current]);

  return (
    <div className={containerStyle}>
      <Map
        ref={map}
        currentSelection={currentSelection}
        currentMap={currentMap}
        mapStyle={mapStyle}
        className="absolute w-full h-screen"
      />
    </div>
  );
}

const containerStyle = clsx("absolute top-0 left-0 w-full h-screen");
