import clsx from "clsx";

export const container = clsx(
  "relative h-[100dvh] w-full overflow-hidden lg:flex lg:flex-row"
);
export const childContainer = clsx(
  "fixed w-full left-0 top-0 lg:relative h-screen z-40"
);
export const mainContainer = clsx(
  "w-full m-auto h-auto lg:m-0 lg:ml-auto mb-4 py-4 lg:left-0 lg:w-3/5 absolute top-0 lg:right-0"
);

export const searchAndTitleContainer = clsx(
  "fit-content m-auto flex justify-center lg:justify-end"
);
export const buttonAdvanceFeaturesDesktop = clsx(
  "z-30 ml-auto hidden h-fit w-fit lg:flex"
);
export const buttonAdvanceFeaturesMobile = clsx(
  "absolute left-0 z-30 ml-4 flex w-8 flex-col gap-4 lg:hidden"
);
export const moreInfoButton = clsx(
  "absolute right-0 mr-4 mt-2 grid gap-1.5 cursor-pointer lg:fixed lg:bottom-6 lg:right-6 lg:flex lg:gap-8"
);
export const ctreesLogo = clsx(
  "absolute bottom-[60px] right-0 mr-1 w-[130px] cursor-pointer lg:bottom-6 lg:hidden"
);
export const desktopGradientLegend = clsx(
  "absolute right-0 mr-2 hidden w-8 lg:block"
);
